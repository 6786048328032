import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./Index.scss";

import PassosAnalise from "../../images/passos/analise.png";
import PassosConta from "../../images/passos/conta.png";
import PassosContrato from "../../images/passos/contrato.png";
import PassosProposta from "../../images/passos/proposta.png";

import { BrowserView, MobileView } from "react-device-detect";
import Faq from "../../Components/Faq/Faq";
import Formulario from "../../Components/Formulario/Formulario";

export default function Index() {

  return (
    <section id="vendadeprecatorios">
      <section id="banner">
        <div className="content">
          <div className="row no-gutters">
            <div className="col-12">
              <h1 className="cl-white f-30 f-wheight-700 mb-4">
                Antecipe seu precatório
                <br /> com segurança e rapidez.
              </h1>
              <p className="cl-white f-16 mb-4">
                Receba o que é de seu direito. Ao vender seu precatório você
                recebe o dinheiro em <br className="d-none d-lg-inline" />{" "}
                pouquíssimos dias, com toda a segurança necessária e pode usar
                da maneira que
                <br className="d-none d-lg-inline" /> for mais conveniente: para
                quitar as dívidas, realizar novos investimentos ou, ainda,
                <br className="d-none d-lg-inline" /> comprar um imóvel ou
                veículo.
                <br />
                <br />
                A FS Consultoria é especialista na Compra e Venda de
                Precatórios. Temos uma <br className="d-none d-lg-inline" />{" "}
                equipe experiente, com economistas e advogados altamente
                qualificados, com
                <br className="d-none d-lg-inline" /> experiência de mais de 10
                anos nesse ramo.
              </p>
              <h2 className="cl-gold f-16 f-wheight-700 mb-4 d-block">
                Nossa metodologia de trabalho é transparente, ágil e segura.
              </h2>
              <a className="bt" href="#form">
                Quero vender meu precatório
              </a>
            </div>
          </div>
        </div>
      </section>
      <section id="passos">
        <div className="content">
          <div className="row">
            <div className="col-12">
              <h2 className="f-25">
                ELIMINE A BUROCACRIA PARA RECEBER SEU PRECATÓRIO.
                <br className="d-none d-lg-inline" />
                COM A FM GESTÃO & INVESTIMENTOS É SIMPLES!
              </h2>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="box-passos row no-gutters">
                    <div className="col-3 box-image d-none d-lg-flex">
                      <img src={PassosAnalise} alt="Analise" />
                    </div>
                    <div className="col-12 col-lg-9 text">
                      <span className="image1">Análise</span>
                      <p>
                        Converse conosco clicando aqui e deixe que nossos
                        <br className="d-none d-lg-inline" /> consultores façam
                        a análise de seus precatórios verificando
                        <br className="d-none d-lg-inline" /> sua elegibilidade
                        e requisitos legais.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 align-right">
                  <div className="box-passos row no-gutters">
                    <div className="col-3 box-image d-none d-lg-flex">
                      <img src={PassosProposta} alt="Proposta" />
                    </div>
                    <div className="col-12 col-lg-9 text">
                      <span className="image2">Proposta</span>
                      <p>
                        Confirmada a viabilidade do precatório federal,
                        <br className="d-none d-lg-inline" /> faremos uma
                        proposta de compra baseada no valor
                        <br className="d-none d-lg-inline" /> atualizado do
                        crédito.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="box-passos row hth2 no-gutters">
                    <div className="col-3 box-image d-none d-lg-flex">
                      <img src={PassosContrato} alt="Contrato" />
                    </div>
                    <div className="col-12 col-lg-9 text">
                      <span className="image3">Contrato firmado</span>
                      <p>
                        Caso a proposta seja aceita, a FM Gestão &amp;
                        <br className="d-none d-lg-inline" /> Investimentos
                        marca com você em um cartório próximo
                        <br className="d-none d-lg-inline" /> de sua residência,
                        na presença de um de nossos
                        <br className="d-none d-lg-inline" /> advogados para
                        assinatura do contrato.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6  align-right">
                  <div className="box-passos row hth2 no-gutters">
                    <div className="col-3 box-image d-none d-lg-flex">
                      <img src={PassosConta} alt="Conta" />
                    </div>
                    <div className="col-12 col-lg-9 text">
                      <span className="image4">Valor na conta</span>
                      <p>
                        Ainda no cartório, realizamos a transferência do valor
                        <br className="d-none d-lg-inline" /> acordado para a
                        sua conta, e somente após a comprovação{" "}
                        <br className="d-none d-lg-inline" /> dodepósito
                        solicitaremos a devolução da documentação
                        <br className="d-none d-lg-inline" /> assinada para
                        nosso advogado.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 button-line">
              <a className="bt" href="#form">
                vender meu precatório
              </a>
            </div>
          </div>
        </div>
      </section>
      <section id="depoimentos">
        <div className="content">
          <div className="row">
            <div className="col-12">
              <div className="row box-depoimento">
                <div className="col-12 col-lg-5"></div>
                <div className="col-12 col-lg-7">
                  <h3>
                    O que nossos parceiros falam sobre{" "}
                    <br className="d-none d-lg-inline" /> os nossos serviços.
                  </h3>
                  <div className="depo active">
                    <p>
                      Ótimo atendimento, muito educados e atenciosos. E a
                      negociação <br className="d-none d-lg-inline" /> ocorreu
                      tranquila. Foi muito bom fazer negócios com vocês!
                    </p>
                    <span>
                      <strong>Maria Izabel Ibanez</strong> | São Paulo - SP
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Faq />

      <Formulario />

    </section>
  );
}
