import React, { useState } from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./Sobre.scss";

import Pig from "../../images/duvidas/pig.png";
import Arrow from "../../images/duvidas/arrow.svg";

import { BrowserView, MobileView } from "react-device-detect";

export default function Index() {
  const [faq, setFaq] = useState(1);

  return (
    <section id="sobre">
      <section id="banner">
        <div className="content">
          <div className="row">
            <div className="col-12">
              <h1 className="cl-white f-30 mb-4">
                CONHEÇA A <b>FS CONSULTORIA</b>
              </h1>
              <p className="cl-white mb-4">
                <b>
                  Um escritório full service que traz resultados para suas
                  demandas jurídicas.
                </b>
                <br />
                <br />
                Fundado em 2018, o escritório Fernandes & Sousa Gestão e
                Consultoria está localizado no Centro do
                <br className="d-none d-lg-inline" /> Rio de Janeiro e atua no
                modelo Full Service, ou seja, modelo de atuação diversificada,
                abrangendo
                <br className="d-none d-lg-inline" />
                as áreas do direito de maneira multidisciplinar, proporcionando
                a prestação de diversos serviços
                <br className="d-none d-lg-inline" />
                jurídicos em um único lugar.
                <br />
                <br />
                Nossa atuação é voltada ao segmento{" "}
                <span className="cl-gold">Familiar</span>,{" "}
                <span className="cl-gold">Empresarial</span> e{" "}
                <span className="cl-gold">Precatórios</span>. Pautada pelos
                resultados
                <br className="d-none d-lg-inline" /> dos nossos clientes, por
                isso, trabalhamos de forma transversal, sempre com foco na
                adoção de
                <br className="d-none d-lg-inline" /> soluções inovadoras e
                dinâmicas, buscando as melhores possibilidades legais.
                <br />
                <br />
                Com uma equipe de sólida formação, o escritório presta serviços
                para clientes nacionais e<br className="d-none d-lg-inline" />{" "}
                estrangeiros, atuando em vários setores da economia.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div id="sobre">
        <div className="content">
          <div className="row">
            <div className="col-12 text-center">
              <h3 className="f-28 font-weight-bold cl-gold">
                NOSSOS DIFERENCIAIS
              </h3>
            </div>
            <div className="col-12 col-lg-4 d-flex justify-content-center align-itens-center">
              <div className="box-sobre bs1 bg-gray">
                <span className="title">Credibilidade</span>
                <p className="bx1">
                  <b>Por que contratar a FS Gestão Consultoria:</b> Mais do que
                  especialistas, procuramos atuar de forma concreta nas
                  necessidades dos nossos clientes. Por isso, somos pioneiros em
                  full service.
                </p>
                <p className="bx1">
                  Além de tradição e ética profissional, nossos clientes contam
                  com apoio integral em todas as regiões do Brasil.
                </p>
                <p className="bx1">
                  Assim, trazemos agilidade às demandas, otimizamos o trabalho e
                  somos assertivos na tomada de decisão.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-4 d-flex justify-content-center align-itens-center">
              <div className="box-sobre bs2 bg-gold">
                <span className="title">Segurança</span>
                <p className="bx1">
                  Nós atuamos em diferentes áreas, e contamos com a participação
                  de profissionais especializados e experientes em cada uma
                  delas.
                </p>
                <p className="bx1">
                  O atendimento personalizado e o trabalho realizado dentro do
                  conceito da interdisciplinaridade possibilitam resultados
                  eficientes.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-4 d-flex justify-content-center align-itens-center">
              <div className="box-sobre bs3 bg-gray">
                <span className="title">Facilidade</span>
                <p>
                  Agilidade e praticidade, com atenção devida nos prazos e
                  sempre em busca para resolução da lide em um tempo
                  satisfatório.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 text-center d-flex justify-content-center align-itens-center">
            <Link className="bt" to="/atuacao">
              Aproveite e conheça nossas áreas de atuação
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
