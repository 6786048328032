import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Scrollchor } from "react-scrollchor";
import "./Atuacao.scss";

export default function Index() {
  return (
    <section id="atuacao">
      <section id="banner">
        <div className="content">
          <div className="row">
            <div className="col-12">
              <h1 className="cl-white f-42 f-wheight-700 mb-4">
                ÁREAS DE ATUAÇÃO
              </h1>
              <p className="cl-white f-18 mb-4">
                Atendemos demandas de natureza jurídico-empresarial e{" "}
                <br className="d-none d-lg-inline" />
                nos destacamos pela eficácia e credibilidade proporcionadas{" "}
                <br className="d-none d-lg-inline" />
                aos nossos clientes.{" "}
                <b>
                  A ética e a transparência são nossas{" "}
                  <br className="d-none d-lg-inline" />
                  prioridades.
                </b>
              </p>
              <p className="cl-gold f-18 f-wheight-700 mb-3">
                Conheça as áreas em que atuamos:
              </p>
              <a
                href="#familia"
                className="f-16 f-wheight-700 mt-3 d-inline-block mb-3"
              >
                Família, Sucessões e Planejamento Patrimonial
              </a>
              <br />  
              <a
                href="#empresarial"
                className="f-16 f-wheight-700 d-inline-block mr-3"
              >
                EMPRESARIAL
              </a>
              <a
                href="#precatorios"
                className="f-16 f-wheight-700 mr-3 d-inline-block"
              >
                PRECATÓRIOS
              </a>

              <br />
            </div>
          </div>
        </div>
      </section>

      <section id="conteudo">
        <div className="content">
          <div className="row">
            <div className="col-12 empresarial" id="familia">
              <h3 className="f-28 cl-gold f-wheight-700">
                Família, Sucessões e Planejamento Patrimonial
              </h3>
              <div className="row">
                <div className="col-12 col-lg-4 list">
                  <span>Consultivo Família e Sucessões</span>
                  <p>Elaboração de testamentos;</p>
                  <p>Elaboração de pactos antinupciais;</p>
                  <p>Contratos de união estável e de namoro;</p>
                  <p>Nomeação de tutores e curadores;</p>
                  <p>Acordos relacionados a matéria de família e sucessões;</p>
                  <p>
                    Pareceres relacionados a matéria de família e sucessões.
                  </p>
                </div>

                <div className="col-12 col-lg-4 list">
                  <span>Contencioso Família e Sucessões</span>
                  <p>Divórcio e separação judicial;</p>
                  <p>Separação de corpos;</p>
                  <p>Reconhecimento e dissolução de união estável;</p>
                  <p>Partilha, sobrepartilha e arrolamento de bens;</p>
                  <p>Testamento e inventário;</p>
                  <p>Tutela, curatela e interdição;</p>
                  <p>Alimentos, guarda e visitas;</p>
                  <p>Execução de alimentos;</p>
                  <p>Investigação e reconhecimento de paternidade;</p>
                  <p>Retificação de registro civil;</p>
                  <p>Planejamento patrimonial (familiar e sucessório).</p>
                </div>

                <div className="col-12 col-lg-4 list">
                  <span>Planejamento Patrimonial</span>
                  <p>
                    Estruturar e/ou organizar os bens familiares visando a
                    questão da sucessão;
                  </p>
                  <p>
                    Estruturar e/ou organizar a empresa familiar visando a
                    questão da sucessão;
                  </p>
                  <p>
                    Constituição de sociedades visando o planejamento
                    patrimonial;
                  </p>
                  <p>
                    Reorganização dos bens familiares ou de empresa familiar por
                    meio da criação de sociedades, fusão, cisão, incorporação e
                    alteração do tipo societário;
                  </p>
                  <p>
                    Elaboração de acordos e atas de acionistas ou quotistas em
                    relação às questões sucessórias.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 familia" id="empresarial">
              <h3 className="f-28 cl-gold f-wheight-700">EMPRESARIAL</h3>
              <div className="row">
                <div className="col-12">
                  <p>
                    Contratos empresariais: negociação, elaboração, revisão e
                    discussão das minutas contratuais;
                  </p>
                  <p>
                    Recuperação Judicial, Extrajudicial e Falência: assessoria
                    jurídica a devedores e a credores;
                  </p>
                  <p>
                    Litígios empresariais: propositura de ações judiciais e
                    apresentação de defesas, inclusive em segunda instância e
                    tribunais superiores;
                  </p>
                  <p>
                    Marcas e patentes: respostas a consultas e elaboração de
                    opiniões legais e adoção de medidas judiciais e
                    extrajudiciais fundadas na Lei de Propriedade Industrial;
                  </p>
                  <p>
                    Mercado de Capitais: assessoria jurídica a companhias e aos
                    seus órgãos; assessoria jurídica a administradores e
                    gestores de fundos de investimento; atuação em processos
                    administrativos perante a CVM; respostas a consultas e
                    elaboração de opiniões legais.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 map" id="precatorios">
              <h3 className="f-28 cl-gold f-wheight-700">PRECATÓRIOS</h3>
              <div className="dual-list">
                <div className="list-1">
                  <div>
                    <p>Precatório Municipal</p>
                    <span>Rio de Janeiro</span>
                  </div>
                  <div>
                    <p>Precatório Estadual</p>
                    <span>Rio de Janeiro</span>
                  </div>
                  <div>
                    <p>Precatório Federal TRF2</p>
                    <span>Rio de Janeiro</span>
                    <span>Espírito Santo</span>
                  </div>
                </div>

                <div className="list-1">
                  <div>
                    <p>Precatório Municipal</p>
                    <span>São Paulo</span>
                  </div>
                  <div>
                    <p>Precatório Estadual</p>
                    <span>São Paulo</span>
                  </div>
                  <div>
                    <p>Precatório Federal TRF3</p>
                    <span>São Paulo</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div id="box">
        <div className="content">
          <div className="row">
            <div className="col-12">
              <div className="box-content d-flex align-items-center justify-content-center flex-column">
                <h4>
                  Conte com a experiência e a credibilidade{" "}
                  <br className="d-none d-lg-inline" />
                  da FS Consultoria
                </h4>
                <Link className="bt" to="/contato">
                  ENTRE EM CONTATO
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
