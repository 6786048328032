import React, { useState } from "react";
import Arrow from "../../images/duvidas/arrow.svg";
import Pig from "../../images/duvidas/pig.png";

import "./Faq.scss";


export default function Faq() {
  const [faq, setFaq] = useState(1);

  return (
    <div id="faq" >
      <div className="content">
        <div className="row">
          <div className="col-12 col-lg-8">
            <h3>PERGUNTAS FREQUENTES</h3>
            <div className="box-faq">
              <a
                className={`${faq === 1 ? "active" : ""}`}
                onClick={(e) => setFaq(1)}
              >
                <span>Compra de precatório é legal?</span>
                <img src={Arrow} alt="arrow" />
              </a>
              <p className={`${faq === 1 ? "active" : ""}`}>
                Sim. A venda de Precatórios é prevista no artigo 286 do Código
                Civil* e no artigo 100 da nossa Constituição. Todo o processo
                será feito mediante contrato entre cliente e a FM Gestão &amp;
                Investimentos e contará com respaldo total da lei. Quero obter
                mais informações!
              </p>
            </div>
            <div className="box-faq">
              <a
                className={`${faq === 2 ? "active" : ""}`}
                onClick={(e) => setFaq(2)}
              >
                <span>Vender meus precatórios é uma boa opção?</span>
                <img src={Arrow} alt="arrow" />
              </a>
              <p className={`${faq === 2 ? "active" : ""}`}>
                Se a venda for feita para uma empresa séria e consolidada, como
                a FM investimentos, sim! O pagamento de precatórios pelas
                Instituições Públicas pode levar muitos anos. Com a FM Gestão
                &amp; Investimentos, após contrato firmado, com o credor ainda
                no cartório, o pagamento poderá ser efetuado imediatamente. Não
                espere mais, entre em contato conosco e receba o valor a que tem
                direito.
              </p>
            </div>
            <div className="box-faq">
              <a
                className={`${faq === 3 ? "active" : ""}`}
                onClick={(e) => setFaq(3)}
              >
                <span>De quais documentos preciso?</span>
                <img src={Arrow} alt="arrow" />
              </a>
              <p className={`${faq === 3 ? "active" : ""}`}>
                Para realização do contrato, solicitamos apenas o envio de
                alguns documentos pessoais como: RG, CPF, comprovante de
                residência e certidão de comprovação de estado civil e os dados
                da conta bancária para depósito do valor. Não solicitamos senhas
                bancárias, códigos de segurança ou número de cartão. Nunca
                forneça esses dados e desconfie, pois essa não é uma prática
                legal. Fale com nossos consultores!
              </p>
            </div>
            <div className="box-faq">
              <a
                className={`${faq === 4 ? "active" : ""}`}
                onClick={(e) => setFaq(4)}
              >
                <span>Pagamento e finalização</span>
                <img src={Arrow} alt="arrow" />
              </a>
              <p className={`${faq === 4 ? "active" : ""}`}>
                Não trabalhamos com valores futuros. Realizamos 100% do valor
                acordado referente ao pagamento do precatório e com o contrato
                em mãos. Ou seja, só após comprovar o recebimento total do
                valor, o cliente devolverá a documentação assinada para nosso
                advogado, finalizando, assim, o negócio da forma mais segura e
                transparente possível. Quero vender meu Precatório de forma
                segura, para a FM Gestão &amp; Investimentos.
              </p>
            </div>
          </div>
          <div className="col-4">
            <img className="faqimage" src={Pig} />
          </div>
          <div className="col-12 text">
            <p>
              Não trabalhamos com valores futuros. Realizamos 100% do valor
              acordado referente ao pagamento do precatório e com o contrato em
              mãos. Ou seja, só após comprovar o recebimento total do valor, o
              cliente devolverá a documentação assinada para nosso advogado,
              finalizando, assim, o negócio da forma mais segura e transparente
              possível.
              <a href="#form">
                {" "}
                Quero vender meu Precatório de forma segura, para a FM
                Investimentos.
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
