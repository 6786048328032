import React, { useState } from "react";
import { Link } from "react-router-dom";
import "react-alice-carousel/lib/alice-carousel.css";
import "./Blog.scss";

import Notice from "../../Components/Notice/Notice";

export default function Blog() {
  return (
    <section id="blog">
      <section className="line-menu" />
      <section id="banner">
        <div className="content">
          <div className="row">
            <div className="col-12">
              <h1 className="cl-gold f-42 f-wheight-700 mb-4">
                Blog e Notícias{" "}
              </h1>
              <p className="cl-black f-19 mb-4">
                Acompanhe as principais notícias lorem ipsum dolor sit amet.
              </p>
              <a
                href="#precatorios"
                className="f-16 f-wheight-700 mt-3 d-inline-block mr-4"
              >
                Precatórios
              </a>
              <a
                href="#empresarial"
                className="f-16 f-wheight-700 d-inline-block mr-4"
              >
                Empresarial
              </a>
              <a
                href="#familia"
                className="f-16 f-wheight-700  d-inline-block"
              >
                Processos de Família
              </a>
            </div>
          </div>
        </div>
      </section>
      <Notice />
    </section>
  );
}
